'use client';


import * as React from 'react';
import Box from '@mui/material/Box';
import dynamic from 'next/dynamic';
import { CmsBlock as CmsBlockData } from '@/services/cms/blocks';
import { CmsBaseBlock } from '@/services/cms/blocks/BaseCmsBlock';
import { LocationMapBlock } from './LocationMapBlock';

const BannerBlock = dynamic(() => import('./BannerBlock').then((mod) => mod.BannerBlock));
const BlockContainer = dynamic(() => import('./BlockContainer').then((mod) => mod.BlockContainer));
const BlockTitle = dynamic(() => import('./components/BlockTitle').then((mod) => mod.BlockTitle));
const BlogPostBlock = dynamic(() => import('./BlogPostBlock').then((mod) => mod.BlogPostBlock));
const DealsBlock = dynamic(() => import('./DealsBlock').then((mod) => mod.DealsBlock));
const EmbedSocialBlock = dynamic(() => import('./EmbedSocialBlock').then((mod) => mod.EmbedSocialBlock));
const FileBlock = dynamic(() => import('./FileBlock').then((mod) => mod.FileBlock));
const MultiColumnBlock = dynamic(() => import('./MultiColumnBlock').then((mod) => mod.MultiColumnBlock));
const TestimonialsBlock = dynamic(() => import('./TestimonialsBlock').then((mod) => mod.TestimonialsBlock));
const VideoTeaserBlock = dynamic(() => import('./VideoTeasrBlock').then((mod) => mod.VideoTeaserBlock));
const ElementContentBlock = dynamic(() => import('./ElementContentBlock').then((mod) => mod.ElementContentBlock));
const ElementListBlock = dynamic(() => import('./ElementListBlock').then((mod) => mod.ElementListBlock));
const FleetTypeCardsBlock = dynamic(() => import('./FleetTypeCardsBlock').then((mod) => mod.FleetTypeCardsBlock));
const HeroBlock = dynamic(() => import('./HeroBlock').then((mod) => mod.HeroBlock));
const MarketingCardsBlock = dynamic(() => import('./MarketingCardsBlock').then((mod) => mod.MarketingCardsBlock));
const MarketingCampaignBlock = dynamic(() => import('./MarketingCampaignBlock').then((mod) => mod.MarketingCampaignBlock));
const TabBlock = dynamic(() => import('./TabBlock').then((mod) => mod.TabBlock));
const PageGalleryBlock = dynamic(() => import('./PageGalleryBlock').then((mod) => mod.PageGalleryBlock));
const PhoneNumberBlock = dynamic(() => import('./PhoneNumberBlock').then((mod) => mod.PhoneNumberBlock));
const ElementEmbeddedCodeBlock = dynamic(() => import('./ElementEmbeddedCodeBlock').then((mod) => mod.ElementEmbeddedCodeBlock));
const TrustPilotBlock = dynamic(() => import('./TrustPilotBlock').then((mod) => mod.TrustPilotBlock));

export interface CmsBlockProps {
    data: CmsBlockData;
    position: number;
    isSubBlock?: boolean;
}

export const CmsBlock = (props: CmsBlockProps) => {
    const blockData = props.data;
    if (blockData.className === 'BannerBlock') {
        return <BannerBlock {...blockData} />;
    } else if (blockData.className === 'DealsBlock') {
        return <DealsBlock {...blockData} />;
    } else if (blockData.className === 'ElementBlogPosts') {
        return <BlogPostBlock {...blockData} />;
    } else if (blockData.className === 'ElementContent') {
        return <ElementContentBlock {...blockData} />;
    } else if (blockData.className === 'ElementEmbeddedCode') {
        return <ElementEmbeddedCodeBlock {...blockData} />;
    } else if (blockData.className === 'ElementList') {
        return <ElementListBlock {...blockData} />;
    } else if (blockData.className === 'EmbedSocialBlock') {
        return <EmbedSocialBlock {...blockData} />;
    } else if (blockData.className === 'FileBlock') {
        return <FileBlock {...blockData} />;
    } else if (blockData.className === 'FleetTypeCardsBlock') {
        return <FleetTypeCardsBlock {...blockData} />;
    } else if (blockData.className === 'HeroBlock') {
        return <HeroBlock {...blockData} />;
    } else if (blockData.className === 'LocationMapBlock') {
        return <LocationMapBlock {...blockData} />;
    } else if (blockData.className === 'MarketingCardsBlock') {
        return <MarketingCardsBlock {...blockData} />;
    } else if (blockData.className === 'MarketingCampaignBlock') {
        return <MarketingCampaignBlock {...blockData} />;
    } else if (blockData.className === 'MultiColumnBlock') {
        return <MultiColumnBlock {...blockData} />;
    } else if (blockData.className === 'PageGalleryBlock') {
        return <PageGalleryBlock {...blockData} />;
    } else if (blockData.className === 'PhoneNumberBlock') {
        return <PhoneNumberBlock {...blockData} />;
    } else if (blockData.className === 'TabBlock') {
        return <TabBlock {...blockData} />;
    } else if (blockData.className === 'TestimonialsBlock') {
        return <TestimonialsBlock {...blockData} />;
    } else if (blockData.className === 'TrustPilotBlock') {
        return <TrustPilotBlock {...blockData} />;
    } else if (blockData.className === 'VideoTeaserBlock') {
        return <VideoTeaserBlock {...blockData} />;
    }

    return (
        <BlockContainer {...(blockData as CmsBaseBlock)}>
            <BlockTitle {...(blockData as CmsBaseBlock)} />
            <Box>Unknown block type {props?.data?.className}</Box>
        </BlockContainer>
    );
};
