'use client';

import React from 'react';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import { mergeSx } from '@jucy-ui/appearance';
import { CmsBaseBlock } from '@/services/cms/blocks/BaseCmsBlock';
import { poppinsBold, poppinsExtraBold } from '@/styles/fonts';
import { headingFontSizes } from '@/styles/theme';

export interface BlockTitleProps extends CmsBaseBlock {
    sx?: SxProps<Theme>;
}

export const BlockTitle = ({ sx, title, showTitle, titleColor, titleStyle, titleTextAlign, titleTag }: BlockTitleProps) => {
    const theme = useTheme();
    if (!title || !showTitle) {
        return null;
    }

    const tag = titleTag?.toLowerCase() || 'default';
    const component = (tag === 'default' ? 'h2' : tag) as React.ElementType;
    const sxTitleStyle = getTitleStyle(titleStyle);
    const sxTextAlign = getTitleTextAlign(titleTextAlign);
    const sxTitleColor = getTitleColor(titleColor, theme);
    const finalStyle = {
        ...sxTitleStyle,
        ...sxTextAlign,
        ...sxTitleColor,
    };

    return (
        <Typography variant="title1" component={component} sx={mergeSx(finalStyle, sx)}>
            {title}
        </Typography>
    );
};

const getTitleColor = (titleColor: string | undefined, theme: Theme): SxProps<Theme> => {
    if (titleColor === 'Green') {
        return {
            color: theme.colors.secondary.main,
        };
    } else if (titleColor === 'Purple') {
        return {
            color: theme.colors.primary.main,
        };
    }

    return {
        color: 'inherit',
    };
};

const getTitleTextAlign = (titleTextAlign: string | undefined): SxProps<Theme> => {
    if (titleTextAlign === 'Center') {
        return {
            textAlign: 'center',
        };
    } else if (titleTextAlign === 'Right') {
        return {
            textAlign: 'right',
        };
    }

    return {
        textAlign: 'left',
    };
};

const getTitleStyle = (titleStyle: string | undefined): SxProps<Theme> => {
    if (titleStyle === 'Bold') {
        return {
            fontFamily: `${poppinsBold.style.fontFamily}`,
            fontSize: '27px',
            fontWeight: 700,
            mb: 4,
        };
    }

    if (titleStyle === 'Extra Bold') {
        return {
            fontFamily: `${poppinsExtraBold.style.fontFamily}`,
            fontSize: '27px',
            fontWeight: 900,
            mb: 4,
        };
    }

    if (titleStyle === 'Hero') {
        return {
            mb: 4,
            textTransform: 'uppercase',
            ...headingFontSizes.h1,
            textAlign: 'center',
            px: 2,
        };
    }

    if (titleStyle === 'Small') {
        return {
            fontSize: '20px',
        };
    }

    if (titleStyle === 'Small Bold') {
        return {
            fontFamily: `${poppinsBold.style.fontFamily}`,
            fontSize: '20px',
            fontWeight: 700,
        };
    }

    if (titleStyle === 'Small Extra Bold') {
        return {
            fontFamily: `${poppinsExtraBold.style.fontFamily}`,
            fontSize: '20px',
            fontWeight: 900,
        };
    }

    return {
        mb: 4,
    };
};
